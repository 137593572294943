import React, { Component, Fragment } from 'react'
import NavMenu from '../Componenets/Common/NavMenu'

export class HomePage extends Component {
  render() {
    return (
      <Fragment>
        <NavMenu></NavMenu>
        <h1>Homepage</h1>
      </Fragment>
    )
  }
}

export default HomePage