class AppURL{

    static BaseURL="http://127.0.0.1:8000/"

static SingleStockItem(item){
        return this.BaseURL+"store/single-stock/"+item;

    }

    static SingleStockIssued(item){
        return this.BaseURL+"store/stock-issued/"+item;

    }


    static StockDamaged(item){
        return this.BaseURL+"store/stock-damaged/"+item;

    }

    static StockIssuingDelete(item){
        return this.BaseURL+"store/stock-issued/"+item+"/";

    }

    static StockReleaseUpdate(item){
        return this.BaseURL+"store/stock-issued/"+item+"/";

    }


    static StockValueUpdate(item){
        return this.BaseURL+"store/stock-avalibility/"+item;

    }



    static IssueStock = this.BaseURL+"/store/stock-issuing"
    
    static DamageStock = this.BaseURL+"store/adding-damage"

    static StockIssuedStatus(item){
        return this.BaseURL+"store/updateIssuedItemStatus/"+item;

    }

    static ProjectList = this.BaseURL+"/store/projects"
    static StockList = this.BaseURL+"/store/stock"
    static ProjectDetail(project){
        return this.BaseURL+"store/projects/"+project;

    }

    static ItemByProject(project){
        return this.BaseURL+"store/item-by-projects/"+project;

    }

    
  //  static ProjectDetail(project){
    //    return this.BaseURL+"store/items/"+project;
//
//    }

    

    static StatusList = this.BaseURL+"/store/status"
    static ItemAll = this.BaseURL+"/store/items"

    //MTO URL

    static MTOList = this.BaseURL+"/mto-list"
    static ItemByMto(mto){
        return this.BaseURL+"mto-item/"+mto;

    }

    static AddMTOItem = this.BaseURL+"/add-mtoitem"
    static MTOItemDelete(item){
        return this.BaseURL+"delete-mto-item/"+item;
    }

    static AddMTO = this.BaseURL+"/add-mto"
    
    
    //http://127.0.0.1:8000/mto-list
    //static ItemList = this.BaseURL+"/store/items"
    static ItemList(item){
        return this.BaseURL+"store/items/"+item;
    }

    //adding-damage
}
export default AppURL