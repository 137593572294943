import React, { Component, Fragment } from 'react'
import NavMenu from '../Componenets/Common/NavMenu'
import Stock from '../Componenets/Stock/Stock'

export class StockPage extends Component {
  render() {
    return (
        <Fragment>
        <NavMenu></NavMenu>
      <Stock></Stock>   
      </Fragment>
       )
  }
}

export default StockPage