import React, { Component, Fragment ,useEffect,useState,useReducer} from 'react'
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'
import NavMenu from '../Componenets/Common/NavMenu';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Item from '../Componenets/Stock/Item'
import axios from 'axios';
import AppURL from '../api/AppURL';
import cogoToast from 'cogo-toast';
import SelectSearch from 'react-select-search';
import { useRef } from "react";
import 'react-select-search/style.css'
import FuzzySearch from 'fuzzy-search'; // Or: var FuzzySearch = require('fuzzy-search');
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import Async, { useAsync } from 'react-select/async';
import {Link, useNavigate} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Alert from 'react-bootstrap/Alert';


function MTODetailPage(){

    const[countries,setCountries]=useState([]);
    const[mto,setMTO]=useState([]);
    const[status,setStatus]=useState([]);
    const [error,setError]=useState([]);
    const location = useLocation();
    const[item,setItem]=useState([]);
    const MTOid=location.state.MTOid;
// const ItemIDStock=location.state.Id;
   //const StockID=location.state.Sid;
   const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

   const [checkoutInput,SetCheckoutInput]=useState({
    status:'',

    });

    useEffect(()=>{
        getCountries();
    },[]);


    useEffect(()=>{
        axios.get(AppURL.ItemByMto(MTOid)).then(response=>{
        setItem(response.data);
        //alert(item.is_both);
        })
    },[ignored]);

  const [region, setRegion] = useState("");
  
  const getCountries = async()=>{
    try{
        const response= await axios.get(AppURL.StockList)
        setCountries(response.data);

    }catch(error){
        console.log(error);
    }
}

const handleSubmit = (e) => {
    e.preventDefault();
if(e.target.quantity.value===''){
    cogoToast.error("Please enter quantity",{position:'top-right'});   
}


else if(e.target.item.value===''){
    cogoToast.error("Please Select Item",{position:'top-right'});   
}

else if(e.target.color.value===''){
    cogoToast.error("Color Field can not be empty",{position:'top-right'});   
}

else{
        const data={
            itemname:e.target.item.value,
            //Issued_item:1,
            quantity:e.target.quantity.value,
            color:e.target.color.value,
            revision:'1',
            mto:MTOid,


        }

        axios.post(AppURL.AddMTOItem,data).then(response =>{  
            if(response.status===201){
                 cogoToast.success("Item Added To MTO",{position:'top-right'});
                // const  newitem =  response.data;
                // setItem(oldItem=>[...oldItem,newitem])
                   forceUpdate();

            }
                
        })



}
    
};


const DeleteMTOItem=(e,mtoitemid)=>{
    e.preventDefault();
    const thisClicked=e.currentTarget;
    thisClicked.innerText="Removing";
    axios.delete(AppURL.MTOItemDelete(mtoitemid)).then(response=>{
        //alert(response.data.message);
        thisClicked.closest("tr").remove();
        cogoToast.success("Item Deleted Successfully",{position:'top-right'});
                
        
        })
    }




   return(
        <>
                <NavMenu></NavMenu>
               
      <div className='container-fluid'>

      <Tab.Container id="left-tabs-example" defaultActiveKey="second">
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="first">Import MTO Item</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Add MTO Item</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="first">
          
                
            </Tab.Pane>
            <Tab.Pane eventKey="second">
            <Form onSubmit={handleSubmit}>
            <div className='row col-md-12'>
            <div className='col-md-8'>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Select Item</Form.Label>
                    <>
                    <Select
                        name='item'
                        rules={{ required: true }}
                        value={region}
                        required={true}
                        onChange={(item) => {
                       // console.log(item);
                        setRegion(item);
                        
                        }}
                        options={countries.map((guest, index) => {
                        return {
                            label: guest.item['itemcode']+" - "+guest.item['name']+" - "+guest.item['description']+" - "+guest.item['length']+" - "+guest.item['width'],
                            value: guest.item['id'],
                            key: index,
                        };
                        })}
                    />
             
                 {region.value
                 }
                    </>

                    <Form.Text className="text-muted">
                        Please select the item ,add quantity and then click on + button .
                    </Form.Text>
                </Form.Group>
                </div>
                <div className='col-md-3'>
                <Form.Group className="mb-3 form-inline" controlId="formBasicEmail">
                    <Form.Label>Qty</Form.Label>
                  <span className='form-inline input-group'>  
                  <Form.Control type="text" placeholder="" name='quantity'  />
                  <Form.Control type="text" placeholder="" name='color' Value="Without"  />
                    <Button variant="primary" type="submit">+</Button>
                    </span>
                    </Form.Group>

                    </div>

                </div>
             </Form>
             <div className='mt-5'></div>

             <Alert variant="primary">
          Here is the list of MTO items you added.
        </Alert>
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Code</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>

        {

                item.map((single,i)=>{
                    return(

                        <tr key={i}>

                            <td>{i+1}</td>
                            <td>{single.itemname['name']}</td>
                            <td>{single.itemname['itemcode']}</td>
                            <td><button className='btn btn-danger'  onClick={(e)=>DeleteMTOItem(e,single.id)}><i className="fa-solid fa-trash"></i></button></td>
                        </tr>
                    )
                })
        }
      
      </tbody>
    </Table>

            </Tab.Pane>
            
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>




     
      </div>
      </>

    )
}
export default MTODetailPage
