import React, { Component, Fragment ,useState} from 'react'
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'
import NavMenu from '../Componenets/Common/NavMenu';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Item from '../Componenets/Stock/Item'

function ItemPage(){

    const location = useLocation();
  
   const item_id=location.state.id;
      
   // alert(location.state.id);
    return(
      <Fragment>
      <NavMenu></NavMenu>
      <Item item={item_id}></Item>

      </Fragment>
    )
}
export default ItemPage