import React, { Component, Fragment } from "react";
import { Route, Routes, Router, Switch } from "react-router";
import HomePage from "../Pages/HomePage";
import ItemIssuedEditPage from "../Pages/ItemIssuedEditPage";
import ItemPage from "../Pages/ItemPage";
import ManageItemIssuing from "../Pages/ManageItemIssuing";
import StockPage from "../Pages/StockPage";
import ProjectPage from "../Pages/ProjectPage";
import ProjectDetailPage from "../Pages/ProjectDetailPage";
import CreateMTOPage from "../Pages/CreateMTOPage";
import MTODetailPage from "../Pages/MTODetailPage";

export class AppRoute extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/stock" element={<StockPage />} />
        <Route path="/item-detail" element={<ItemPage />} />
        <Route path="/manage-item" element={<ManageItemIssuing />} />
        <Route path="/issued-item-edit" element={<ItemIssuedEditPage />} />
        <Route path="/projects" element={<ProjectPage />} />
        <Route path="/project-detail" element={<ProjectDetailPage />} />
        <Route path="/project-mto" element={<CreateMTOPage />} />
        <Route path="/mto-detail" element={<MTODetailPage />} />
      </Routes>
    );
  }
}

export default AppRoute;
