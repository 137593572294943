import React, { Component, useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'
import {Link, useNavigate} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import ItemPage from '../../Pages/ItemPage';
import { Navigate } from 'react-router-dom';


function Stock(){

    const navigate =useNavigate();
    const[countries,setCountries]=useState([]);
    const[search,setSearch]=useState([]);
    const[filteredCountries,setFilteredCountries]=useState([]);
    const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
      };


      const ItemPage=(e,Itemid)=>{
        e.preventDefault();
        navigate('/item-detail',{state:{id:Itemid}});
      
        //thisClicked.closest("tr").remove();
        //console.log(email);
         }
    const getCountries = async()=>{
        try{
            const response= await axios.get('http://127.0.0.1:8000/store/stock')
            setCountries(response.data);
            setFilteredCountries(response.data)
           // console.log(countries);
        }catch(error){
            console.log(error);
        }
    }
    
    const columns =[
        {
            name:"Barcode",
            selector:(row) => row.barcode,
            sortable:true
        }  ,  
        {
            name:"Item",
            selector:(row) => row.item['name'],
            sortable:true
        }  ,  

        {
            name:"Width",
            selector:(row) => row.item['width'],
            sortable:true
        }  ,  

        {
            name:"Type",
            selector:(row) => row.type['name'],
            sortable:true
        }  ,  

        {
            name:"Supplier",
            selector:(row) => row.Supplier['name'],
            sortable:true
        }  ,  

        {
            name:"Length",
            selector:(row) => row.item['length'],
            sortable:true
        }  ,  

        {
            name:"Quantity",
            selector:(row) => row.quantity,
            sortable:true
        }  ,  
        {
            name:"Action",
            cell:(row)=>
            <button className='btn btn-danger'  onClick={(e)=>ItemPage(e,row.id)}><i className="fa-solid fa-share"></i></button>
            //<button className='btn btn-danger' onClick={(e)=> ItemPage()}><i class="fa-solid fa-share"></i></button>
            
        }
    ]

    useEffect(()=>{
        getCountries()
        //console.log(getCountries())
    },[]);

    useEffect(()=> {
        const result=countries.filter(country=>{
            return country.item['name'].toLowerCase().match(search.toLowerCase());
        });
        setFilteredCountries(result);
    },[search])
return(
    <>
    <div className="d-flex flex-column align-items-center">

    <DataTable 
    columns={columns} 
    data={filteredCountries} 
    pagination title="Stock List" 
    fixedHeader 
    fixedHeaderScrollHeight="590px"
    selectableRows
    selectableRowsHighlight
    highlightOnHover
    actions={
        <button className='btn btn-info'>Export</button>
    }
    subHeader
    subHeaderComponent={
        <input 
        type="text" 
        placeholder='Search here' 
        className='w-25 form-control' 
            value={search}
            onChange={(e)=> setSearch(e.target.value)}>
        </input>
    }
    //subHeaderAlign="center"
    ></DataTable>
    </div>
    </>
)

}

export default Stock